import { AfterViewInit, ChangeDetectorRef, Component, ComponentRef, Inject, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthType } from '@core/enums';
import { ButtonType } from '@shared/components/button/button.component';
import { Subject, takeUntil } from 'rxjs';
import { ChangeEmailV2Component } from './components/change-email-v2/change-email-v2.component';
import { DeleteAccountOtpV2Component } from './components/delete-account-otp-v2/delete-account-otp-v2.component';
import { LoginV2Component } from './components/login-v2/login-v2.component';
import { NewEmailV2Component } from './components/new-email-v2/new-email-v2.component';
import { OtpV2Component } from './components/otp-v2/otp-v2.component';
import { WelcomeV2Component } from './components/welcome-v2/welcome-v2.component';
import { InterestSurveyComponent } from './components/interest-survey/interest-survey.component';
import { AbstractAuthComponent } from '../abstracts/auth.abstract';

interface InputDeclarations {
	email?: string;
	authType?: AuthType;
	fromAgentInvitation?: boolean;
	isPostAd?: boolean;
}

@Component({
	selector: 'findqo-auth-dialog-v2',
	templateUrl: './auth-dialog-v2.component.html',
	styleUrls: ['./auth-dialog-v2.component.scss'],
})
export class AuthDialogV2Component implements AfterViewInit {
	constructor(
		@Inject(MAT_DIALOG_DATA) private readonly dialogData: InputDeclarations,
		private readonly dialogRef: MatDialogRef<AuthDialogV2Component>,
		private readonly cdr: ChangeDetectorRef
	) {}

	@ViewChild('container', { read: ViewContainerRef }) containerRef: ViewContainerRef;

	currentAuthType: AuthType;

	readonly buttonTypeEnum = ButtonType;
	readonly authTypeEnum = AuthType;

	private templateMapper = {
		[AuthType.LOGIN]: LoginV2Component,
		[AuthType.OTP]: OtpV2Component,
		[AuthType.WELCOME]: WelcomeV2Component,
		[AuthType.CHANGE_EMAIL]: ChangeEmailV2Component,
		[AuthType.NEW_EMAIL]: NewEmailV2Component,
		[AuthType.UPDATE_EMAIL_OTP]: OtpV2Component,
		[AuthType.NEW_EMAIL_OTP]: OtpV2Component,
		[AuthType.DELETE_OTP]: DeleteAccountOtpV2Component,
		[AuthType.INTEREST_SURVEY]: InterestSurveyComponent,
	};

	onClose(): void {
		this.dialogRef.close({ isClosed: true });
	}

	/**
	 * Dyamically creates a component based on the current Auth type
	 */
	private loadComponent(properties?: InputDeclarations): void {
		this.containerRef.clear();

		const COMPONENT_INSTANCE = this.getComponentType();

		if (this.currentAuthType === AuthType.INTEREST_SURVEY) {
			this.dialogRef.removePanelClass('auth-dialog');
			this.dialogRef.addPanelClass('survey-dialog');
		}

		const COMPONENT_REF = this.containerRef.createComponent(COMPONENT_INSTANCE) as ComponentRef<unknown>;

		// Set Output events @Output
		this.setOutput(COMPONENT_REF);

		// Set @Input()
		if (properties) {
			this.setInput(COMPONENT_REF, { ...properties });
		}

		this.cdr.detectChanges();
	}

	private setOutput(componentRef: ComponentRef<unknown>): void {
		const ngUnSubscribe = new Subject<void>();

		// Listen to `changed` event or changed screen
		if (componentRef.instance?.['changed']) {
			componentRef.instance['changed'].pipe(takeUntil(ngUnSubscribe)).subscribe((properties: InputDeclarations) => {
				this.currentAuthType = properties.authType;
				this.loadComponent({ ...properties, isPostAd: this.dialogData.isPostAd });
			});
		}

		// Destroy the subscription
		componentRef.onDestroy(() => {
			ngUnSubscribe.next();
			ngUnSubscribe.complete();
		});
	}

	private setInput(componentRef: ComponentRef<unknown>, properties: InputDeclarations): void {
		// Set `Input` decorators
		Object.entries(properties).forEach(([key, value]) => {
			componentRef.setInput(key, value);
		});
	}

	private getComponentType(): Type<unknown> {
		return this.templateMapper[this.currentAuthType];
	}

	ngAfterViewInit(): void {
		this.currentAuthType = this.dialogData.authType;
		this.loadComponent(this.dialogData);
	}
}
