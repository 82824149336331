import { Injectable } from '@angular/core';
import { Agents } from '@core/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AgentsState {
	private readonly _agents$ = new BehaviorSubject<Agents[]>(undefined);

	get agents$(): Observable<Agents[]> {
		return this._agents$.asObservable();
	}

	getAgents(): Agents[] {
		return this._agents$.getValue();
	}

	setAgents(data: Agents[]): void {
		this._agents$.next(data);
	}
}
