import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@core/constants';
import { Agent, AgentSignUpBody, Agents, CommonResponse, Seller } from '@core/models';
import { Observable, map } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AgentsApi {
	constructor(private http: HttpClient) {}

	getAgents(): Observable<Agents[]> {
		return this.http.get(API.agents.base).pipe(map((response: { data: Agents[] }) => response?.data));
	}

	//Note the response here we are gettign is temporary response.Once Api gets updated we need to update the response here
	addAgents(emails: string[]): Observable<CommonResponse<[]>> {
		return this.http.post<CommonResponse<[]>>(API.agents.base, { email: emails });
	}

	deleteAgent(agentId: string): Observable<CommonResponse<[]>> {
		return this.http.delete(API.agents.updateAgent(agentId));
	}

	canRemoveAgent(agentId: string): Observable<{ hasAssignedAd: string }> {
		return this.http.post<CommonResponse<{ hasAssignedAd: string }>>(API.agents.agentHasAd(agentId), {}).pipe(map((response) => response?.data));
	}

	getAgentList(action?: string): Observable<Agent[]> {
		return this.http
			.get(API.agents.base, {
				params: {
					status: '2',
					action,
				},
			})
			.pipe(map((response: { data: Agent[] }) => response?.data));
	}

	agentSignUp(body: AgentSignUpBody): Observable<object> {
		return this.http.post(API.agents.signUp, body);
	}

	getBusinessList(): Observable<Seller[]> {
		return this.http.get<CommonResponse<Seller[]>>(API.agents.business).pipe(map((res) => res.data));
	}
}
