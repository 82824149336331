import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthApi } from '@core/data-access/apis/auth.api';
import { ProfileApi } from '@core/data-access/apis/profile.api';
import { AuthService } from '@core/data-access/services/auth.service';
import { ConfigState } from '@core/data-access/states/config.state';
import { ProfileState } from '@core/data-access/states/profile.state';
import { LocaleType } from '@core/enums/locale.enum';
import { AgentInvitation, ClientUser, CommonResponse, SurveyBody, VerifyEmailOtpRequest } from '@core/models';
import { Translations } from '@core/models/translations.model';
import { SnackService } from '@core/services/snack.service';
import { BehaviorSubject, Observable, catchError, finalize, firstValueFrom } from 'rxjs';
import { AuthDialogV2Component } from './v2/auth-dialog-v2.component';
import { AuthType } from '@core/enums';
import { Router } from '@angular/router';
import { APP_ROUTE } from '@core/constants';
import { MyProfileService } from '@core/services/my-profile.service';
import { IntercomService } from '@core/services/intercom.service';

@Injectable({
	providedIn: 'root',
})
export class AuthFacade {
	protected constructor(
		private authApi: AuthApi,
		private authService: AuthService,
		private snackService: SnackService,
		private profileState: ProfileState,
		private configState: ConfigState,
		private profileApi: ProfileApi,
		private dialog: MatDialog,
		private router: Router,
		private profileService: MyProfileService,
		private intercomService: IntercomService
	) {}

	private readonly _isLoading$ = new BehaviorSubject<boolean>(false);

	isLoading$(): Observable<boolean> {
		return this._isLoading$.asObservable();
	}

	setIsLoading(isLoading: boolean): void {
		this._isLoading$.next(isLoading);
	}

	login(body: any, dialogRef: MatDialogRef<any>, clientUser?: ClientUser): void {
		this.authApi
			.login(body)
			.pipe(
				catchError((error) => {
					this.snackService.open({
						data: { message: 'There was problem logging you in. Please try again later.', type: 'error' },
						panelClass: 'snackbar-error',
					});

					throw new Error(error);
				})
			)
			.subscribe((response: any) => {
				this.authService.login(response.data);
				this.profileState.setUser(response.data);

				this.snackService.open({
					data: { message: 'Welcome back!', type: 'info' },
					panelClass: 'snackbar-info',
				});

				dialogRef.close({ isLogin: true, hasName: !!this.authService.user()?.['name'] });
			});
	}

	getTranslations$(): Observable<Translations> {
		return this.configState.translations$;
	}

	getLocale$(): Observable<LocaleType> {
		return this.configState.locale$;
	}

	requestEmailOtp(email: string): void {
		this.setIsLoading(true);

		this.authApi
			.requestEmailOtp(email)
			.pipe(finalize(() => this.setIsLoading(false)))
			.subscribe();
	}

	verifyEmailOtp(request: VerifyEmailOtpRequest): Promise<ClientUser> {
		this.setIsLoading(true);

		return new Promise(async (resolve, reject) => {
			try {
				const RESPONSE = await firstValueFrom(this.authApi.verifyEmailOtp(request));
				this.authService.login(RESPONSE.data);

				this.profileState.updateUser(RESPONSE.data);

				resolve(RESPONSE.data);
			} catch {
				reject(null);
			} finally {
				this.setIsLoading(false);
			}
		});
	}

	updateClientName(name: string, changed?: EventEmitter<any>, fromAgentInvitation = false): void {
		this.profileApi.updateProfile({ name }).subscribe(() => {
			if (!fromAgentInvitation) {
				this.snackService.open({
					data: { message: this.configState.getTranslations()['n2317'] },
					panelClass: 'snackbar-success',
				});
			}

			const USER = {
				...this.profileState.getUser(),
				name,
			};

			this.authService.login({
				...USER,
			});

			this.profileState.setUser({ ...USER });
			this.intercomService.addIntercomUser(USER);

			changed.emit({
				authType: AuthType.INTEREST_SURVEY,
			});
		});
	}

	requestOtpForDeleteAccount(): void {
		this.profileState.setIsLoading(true);

		this.profileApi
			.getRequestOtp()
			.pipe(finalize(() => this.profileState.setIsLoading(false)))
			.subscribe();
	}

	acceptAgentInvitation(agentInvitation: AgentInvitation, inviterName?: string) {
		this.authApi.acceptAgentInvitation(agentInvitation).subscribe((response: { data: ClientUser }) => {
			this.authService.login(response?.data);
			this.profileState.setUser(response?.data);
			this.profileState.setProfileTabIndex(1);

			if (inviterName) {
				this.profileService.setInviterName(inviterName);
			}

			//if new user show welcome screen
			if (!response.data?.name) {
				this.dialog
					.open(AuthDialogV2Component, {
						panelClass: 'auth-dialog',
						disableClose: true,
						data: { authType: AuthType.WELCOME, fromAgentInvitation: true },
					})
					.afterClosed()
					.subscribe(() => {
						if (agentInvitation.type) {
							this.router.navigate([APP_ROUTE.myProfile + '/' + APP_ROUTE.businessProfile.welcome]);
						} else {
							this.router.navigate([APP_ROUTE.myProfile]).then(() => {
								this.snackService.open({
									data: { translationKey: 'n3518', icon: 'check_circle', type: 'success' },
									panelClass: 'snackbar-success',
								});
							});
						}
					});
			} else {
				this.intercomService.addIntercomUser(response?.data);

				if (agentInvitation.type) {
					this.router.navigate([APP_ROUTE.myProfile + '/' + APP_ROUTE.businessProfile.welcome]);
				} else {
					this.router.navigate([APP_ROUTE.myProfile]).then(() => {
						this.snackService.open({
							data: { translationKey: 'n3518', icon: 'check_circle', type: 'success' },
							panelClass: 'snackbar-success',
						});
					});
				}
			}
		});
	}

	rejectAgentInvitation(agentInvitation: AgentInvitation, dialogRef) {
		this.authApi.rejectAgentInvitation(agentInvitation).subscribe(() => {
			this.router.navigate([APP_ROUTE.home]);
			dialogRef.close();
		});
	}

	changeEmailRequestOtp(): void {
		this.setIsLoading(true);

		this.profileApi
			.changeEmailRequestOtp()
			.pipe(finalize(() => this.setIsLoading(false)))
			.subscribe();
	}

	verfiyCurrentEmailOtp(pin: string): Promise<CommonResponse<Record<string, never>>> {
		this.setIsLoading(true);

		return firstValueFrom(
			this.profileApi.verfiyCurrentEmailOtp(pin).pipe(
				catchError((error) => {
					this.snackService.open({
						data: { translationKey: 'n621', type: 'error' },
						panelClass: 'snackbar-error',
					});

					throw new Error(error);
				}),
				finalize(() => this.setIsLoading(false))
			)
		);
	}

	updateEmailOtp(email: string): Promise<CommonResponse<Record<string, never>>> {
		this.setIsLoading(true);

		return firstValueFrom(
			this.profileApi.updateEmailOtp(email).pipe(
				catchError((error) => {
					this.snackService.open({
						data: { message: error?.error?.error?.errors[0]?.messages[0], type: 'error' },
						panelClass: 'snackbar-error',
					});

					throw new Error(error);
				}),
				finalize(() => this.setIsLoading(false))
			)
		);
	}

	verfiyNewEmailOtp(pin: string): Promise<CommonResponse<Record<string, never>>> {
		this.setIsLoading(true);

		return firstValueFrom(
			this.profileApi.verfiyNewEmailOtp(pin).pipe(
				catchError((error) => {
					this.snackService.open({
						data: { translationKey: 'n621', type: 'error' },
						panelClass: 'snackbar-error',
					});

					throw new Error(error);
				}),
				finalize(() => this.setIsLoading(false))
			)
		);
	}

	updateEmail(email: string): void {
		this.profileState.setUser({ ...this.profileState.getUser(), email });
		this.intercomService.updateIntercomUser({ ...this.profileState.getUser(), email });
	}

	submitSurvey(body: Partial<SurveyBody>, dialofRef: MatDialogRef<AuthDialogV2Component>): void {
		this.setIsLoading(true);

		this.profileApi
			.submitSurvey(body)
			.pipe(
				catchError((error) => {
					this.snackService.open({
						data: { translationKey: 'n608', type: 'error' },
						panelClass: 'snackbar-error',
					});

					throw new Error(error);
				}),
				finalize(() => this.setIsLoading(false))
			)
			.subscribe(() => dialofRef.close());
	}
}
